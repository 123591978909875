import(/* webpackMode: "eager", webpackExports: ["EmailForm"] */ "/vercel/path0/app/(layout)/login/EmailForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SignInProviders"] */ "/vercel/path0/app/(layout)/login/ProviderForms.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.0_next@15.0.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.4_pdhx46o2ihtvyb4itt3hm3mefu/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Layout","LayoutContent"] */ "/vercel/path0/src/components/layout/layout-base.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CircularPatternWrapper"] */ "/vercel/path0/src/components/layout/pattern/CircularPatternWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RedirectPage"] */ "/vercel/path0/src/features/server-components/RedirectPage.tsx");
