'use client';

import { useSearchParams } from 'next/navigation';
import { Skeleton } from '~/components/ui/skeleton';
import { SignInWithGitHubButton } from '~/features/auth/SignInWithGitHubButton';
import { SignInWithGoogle } from '~/features/auth/SignInWithGoogle';
import { useProviders } from '~/lib/use-query-hooks/useProviders';
import type { SignInFormProps } from './SignInForm';

export const SignInProviders = (props: SignInFormProps) => {
  const params = useSearchParams();

  const callbackUrl = props.callbackUrl ?? params.get('callbackUrl') ?? '/home';

  const { data: providers } = useProviders();

  return (
    <div className="w-full">
      {providers ? (
        <div className="flex items-center gap-2">
          <SignInWithGitHubButton callbackUrl={callbackUrl} />
          <SignInWithGoogle callbackUrl={callbackUrl} />
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <Skeleton className="h-10 flex-1" />
          <Skeleton className="h-10 flex-1" />
        </div>
      )}
    </div>
  );
};
